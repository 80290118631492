<template>
  <div class="data">
    <div class="data-group">
      <div class="amount">{{ amount }}</div>
      <div class="nk-ecwg6-ck" v-if="hasChart">
        <slot name="chart"></slot>
      </div>
    </div>
    <div class="info" v-if="hasInfoSlot">
      <slot name="info"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    amount: null,
  },
  setup(props, {slots}){

    let hasChart = slots.chart && slots.chart().length
    let hasInfoSlot = slots.info && slots.info().length

    return{
      hasChart,
      hasInfoSlot,
    }
  },
}
</script>
